// src/TwitchContext.js
import { createContext, useContext, useState } from "react";

const TwitchContext = createContext();

export function TwitchProvider({ children }) {
  const [userData, setUserData] = useState(null);

  const setTwitchUserData = (data) => {
    setUserData(data);
  };

  return (
    <TwitchContext.Provider value={{ userData, setTwitchUserData }}>
      {children}
    </TwitchContext.Provider>
  );
}

export function useTwitch() {
  const context = useContext(TwitchContext);
  if (!context) {
    throw new Error("useTwitch must be used within a TwitchProvider");
  }
  return context;
}
