import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTwitch } from "./TwitchContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const RulesPage = () => {
  const { userData, handleLogout } = useTwitch();
  const navigate = useNavigate();

  return (
    <div className="card-container neon-block ">
      <div className="card card-custom block">
        <div className="go-back" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className="rules-container">
          <h1 className="rules-header">Tap Thirsty Rules and Guidelines</h1>

          {userData ? (
            <div>
              <p className="greeting">
                Welcome, <strong>{userData.display_name}</strong>! You currently
                hold <strong>{userData.credits} credits</strong>.
              </p>

              <h2 className="section-header">General Guidelines:</h2>
              <ul>
                <li>
                  Each participant commences with a credit balance of 0 after
                  successfully logging in with Twitch for the first time.
                </li>
                <li>
                  Logout option is conveniently located at the bottom of the
                  page.
                </li>
                <li>
                  Users are encouraged to contribute to the music playlist by
                  adding a new song via the designated button at the bottom of
                  the songs list.
                </li>
              </ul>

              <h2 className="section-header">Voting and Song Submission:</h2>
              <ul>
                <li>
                  Participants receive their{" "}
                  <strong>first vote of the day free of charge</strong> and you
                  will receive <strong>100 credits</strong>.
                </li>
                <li>
                  Additional votes cost <strong>200 credits each</strong>.
                </li>
                <li>
                  Reward: Users earn <strong>100 credits for each vote</strong>{" "}
                  and
                  <strong> 300 credits for every song</strong> successfully
                  added to the playlist.
                </li>
                <li>
                  The playlist is limited to a maximum of{" "}
                  <strong>30 songs</strong>.
                </li>
                <li>
                  Users with a credit balance exceeding{" "}
                  <strong>1600 credits</strong> have the privilege to replace
                  the least voted song, provided they haven't added three songs
                  already in the current week.
                </li>
              </ul>

              <h2 className="section-header">Weekly Live Streaming Show:</h2>
              <p>
                A live streaming show will be hosted periodically, showcasing
                the <strong>top 30 songs</strong> in the playlist.
              </p>
            </div>
          ) : (
            <div>
              <p className="login-prompt">
                To access the rules, please log in with Twitch.
              </p>
              <Link to="/" className="login-link">
                Login with Twitch
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
