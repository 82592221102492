// src/App.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import firebase from "./firebase";
import { useTwitch } from "./TwitchContext";
import SongsList from "./SongsList";
import "bootstrap/dist/css/bootstrap.min.css"; // Add Bootstrap styles
import "bootstrap/dist/js/bootstrap.bundle.min"; // Add Bootstrap JS
import ImThirsty from "./ImThirsty";
import { ModalProvider } from "./ModalContext";
import Moderator from "./Moderator";
import RulesPage from "./RulesPage";
import Logo from "./images/tapThirstyV2.png";
import ShowcasePage from "./Showcase";
import Top10Share from "./Top10Share";
import { Helmet } from "react-helmet";
import TapThirsty from "./images/tapThirstyV2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clientId = "a0yn729ic0td2oa3xnajzodvnz9h67";
const redirectUri =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://tapthirsty.alinzvinca.com"; // Update with your actual redirect URI
const twitchApi = "https://api.twitch.tv/helix";
const requiredScopes =
  "user:read:email channel:read:redemptions channel:manage:redemptions";

function App() {
  const db = firebase.firestore();
  const { userData, setTwitchUserData } = useTwitch();
  const [loading, setLoading] = useState(true);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [banNotified, setBanNotified] = useState(false);
  const [votingEnabled, setVotingEnabled] = useState(true);

  const navigate = useNavigate();
  const notify = (message, type = null) => {
    if (type) toast[type](message);
    else toast(message);
  };

  const fetchBannedUsers = async () => {
    try {
      const bannedUsersSnapshot = await db.collection("bannedUsers").get();
      const bannedUsersData = bannedUsersSnapshot.docs.map((doc) => doc.data());
      setBannedUsers(bannedUsersData);
    } catch (error) {
      console.error("Error fetching banned users:", error);
    }
  };

  const fetchVotingStatus = async () => {
    try {
      const settingsDoc = await db
        .collection("settings")
        .doc("votingStatus")
        .get();
      const votingStatus = settingsDoc.data()?.votingStatus;

      if (votingStatus !== undefined) {
        setVotingEnabled(votingStatus);
      }
    } catch (error) {
      console.error("Error fetching voting status:", error);
    }
  };

  // Fetch banned users on component mount
  useEffect(() => {
    fetchBannedUsers();
    fetchVotingStatus();
  }, []);

  useEffect(() => {
    // Check if the user is banned
    const isBanned = bannedUsers.some(
      (user) =>
        userData &&
        (user.displayName === userData.displayName ||
          user.userId === userData.id)
    );

    if (isBanned) {
      if (!banNotified) {
        notify("YOU HAVE BEEN BANNED FROM THIS APP!", "error");
        setBanNotified(true);
      }
      handleLogout(); // Log out the user if banned
    }
  }, [userData, bannedUsers]);

  useEffect(() => {
    const fetchData = async () => {
      // Check if the URL contains an access token

      const isBanned = bannedUsers.some(
        (user) =>
          userData &&
          (user.displayName === userData.displayName ||
            user.userId === userData.id)
      );

      if (isBanned) return;

      const params = new URLSearchParams(window.location.hash.substr(1));
      const accessToken = params.get("access_token");

      if (accessToken) {
        // Store the token in localStorage
        localStorage.setItem("twitchToken", accessToken);
        // Clear the URL hash
        window.location.hash = "";
        // Fetch user data
        await getUserData(accessToken);
      } else {
        // Check if the user is already authenticated
        const storedToken = localStorage.getItem("twitchToken");
        if (storedToken) {
          await getUserData(storedToken);
        }
      }

      setLoading(false); // Set loading to false once data fetching is done
    };

    fetchData();
  }, []);

  const handleLogin = () => {
    // Redirect user to Twitch authentication
    window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${requiredScopes}`;
  };

  const generateUserId = () => {
    // Generate a random user ID (you may use a more robust method)
    return `user_${Math.floor(Math.random() * 1000000)}`;
  };

  const getChannelPoints = async (
    broadcasterId,
    userId,
    accessToken,
    clientId
  ) => {
    const rewardsUrl = `https://api.twitch.tv/helix/channel_points/custom_rewards?broadcaster_id=${broadcasterId}`;
    const redemptionsUrl = `https://api.twitch.tv/helix/channel_points/custom_rewards/redemptions?broadcaster_id=${broadcasterId}&user_id=${userId}`;

    try {
      // Fetch custom rewards
      const rewardsResponse = await axios.get(rewardsUrl, {
        headers: {
          "Client-ID": clientId,
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const customRewards = rewardsResponse.data.data;

      // Fetch redemptions for the specific user
      const redemptionsResponse = await axios.get(redemptionsUrl, {
        headers: {
          "Client-ID": clientId,
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const userRedemptions = redemptionsResponse.data.data;

      //   console.log("Custom Rewards:", customRewards);
      //   console.log("User Redemptions:", userRedemptions);

      // Process the data as needed
    } catch (error) {
      console.error("Error fetching channel points:", error.response?.data);
      throw error;
    }
  };

  const getUserData = async (token) => {
    try {
      const isBanned = bannedUsers.some(
        (user) =>
          userData &&
          (user.displayName === userData.displayName ||
            user.userId === userData.id)
      );

      if (isBanned) {
        handleLogout(); // Log out the user if banned
        return;
      }
      const response = await axios.get(`${twitchApi}/users`, {
        headers: {
          "Client-ID": clientId,
          Authorization: `Bearer ${token}`,
        },
      });
      const userData = response.data.data[0];
      // Check if the user is banned

      // Check if the user already exists in the database
      const userRef = db.collection("users").doc(userData.id.toString());
      const userDoc = await userRef.get();

      if (!userDoc.exists) {
        // User doesn't exist, create a new document
        userRef.set({
          displayName: userData.display_name,
          avatar: userData.profile_image_url,
          credits: 0, // Set default credits to 0
          userId: generateUserId(), // Set unique user id
        });
      } else {
        // User exists, check if it has a userId
        const storedUserId = userDoc.data().userId;

        if (!storedUserId) {
          // User doesn't have a userId, generate one and update the database
          const newUserId = generateUserId();
          await userRef.update({ userId: newUserId });
        }
      }

      // Set user data in context
      setTwitchUserData({
        ...userData,
        userId: userDoc.data().userId,
        credits: userDoc.data().credits,
      });

      // eslint-disable-next-line react-hooks/rules-of-hooks
      //   window.location.href = "/";

      //   // Fetch broadcaster_id using the broadcaster's login name
      //   const broadcasterLoginName = "alinzvinca"; // Replace with the actual login name
      //   const broadcasterResponse = await axios.get(
      //     `${twitchApi}/users?login=${broadcasterLoginName}`,
      //     {
      //       headers: {
      //         "Client-ID": clientId,
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );

      //   const broadcasterData = broadcasterResponse.data.data[0];
      //   const broadcasterId = broadcasterData.id;

      //   const channelPointsData = channelPointsResponse.data;

      //   // Update the user's data in the database
      //   await userRef.update({
      //     channelPoints: channelPointsData,
      //   });

      //   // Set user data in context
      //   setTwitchUserData({
      //     ...userData,
      //     userId: userDoc.data().userId,
      //     credits: userDoc.data().credits,
      //     channelPoints: channelPointsData,
      //   });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const storeUserData = (userData) => {
    // Check if the user already exists in the database
    const userRef = db.collection("users").doc(userData.id.toString());

    userRef.get().then((doc) => {
      if (!doc.exists) {
        // User doesn't exist, create a new document
        userRef.set({
          displayName: userData.display_name,
          avatar: userData.profile_image_url,
        });
      }
    });
  };

  const handleLogout = () => {
    // Clear user data and token from storage
    localStorage.removeItem("twitchToken");
    setTwitchUserData(null);
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while data is being fetched
  }

  const storedToken = localStorage.getItem("twitchToken");

  return (
    <>
      <Helmet>
        <meta
          property="og:url"
          content={`https://${window.location.hostname}/songs-list`}
        />
        <meta property="og:type" content="music" />
        <meta
          property="og:title"
          content={"Vote your favourite, now! Alin Zvinca Tap Thirsty!"}
        />
        <meta
          property="og:description"
          content={"Check the new TOP Thirty from TAP THIRSTY!"}
        />
        <meta
          property="og:image"
          content={`https://${window.location.hostname}` + TapThirsty}
        />
      </Helmet>

      <ModalProvider>
        <div className="app-container">
          <Routes>
            <Route
              path="/"
              element={
                userData ? (
                  <Navigate to="/songs-list" />
                ) : (
                  <Home handleLogin={handleLogin} />
                )
              }
            />
            <Route
              path="/songs-list"
              element={
                userData || storedToken ? (
                  <SongsList votingEnabled={votingEnabled} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/rules"
              element={userData || storedToken ? <RulesPage /> : <RulesPage />}
            />
            <Route
              path="/moderator"
              element={
                userData || storedToken ? (
                  <Moderator votingEnabled={votingEnabled} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/im-thirsty"
              element={
                userData || storedToken ? <ImThirsty /> : <Navigate to="/" />
              }
            />
            <Route
              path="/showcase/:entryId"
              element={
                userData || storedToken ? (
                  <ShowcasePage votingEnabled={votingEnabled} />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/top10"
              element={
                userData || storedToken ? <Top10Share /> : <Navigate to="/" />
              }
            />
            {/* Catch-all route for unknown paths */}
            <Route path="*" element={<Navigate to="/songs-list" />} />
          </Routes>
        </div>
        <ToastContainer theme="dark" />
      </ModalProvider>
    </>
  );
}

function Home({ handleLogin }) {
  return (
    <div className="welcome-screen">
      <div className="welcome">
        <img src={Logo} alt="Tap Thirsty" />
        <button className="btn-login" onClick={handleLogin}>
          Login with Twitch
        </button>
      </div>
    </div>
  );
}

export default App;
