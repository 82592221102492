// src/ImThirsty.js
import React, { useEffect, useState } from "react";
import { useTwitch } from "./TwitchContext";
import { db } from "./firebase";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { YTDurationToSeconds } from "./functions";
import Coin from "./images/coinLogo.png";
import { useNavigate } from "react-router-dom";
import Icon from "./TapIcon";

function ImThirsty() {
  const { userData } = useTwitch();
  const navigate = useNavigate();
  const [userEntries, setUserEntries] = useState([]);

  useEffect(() => {
    // Fetch entries added by the current user
    const fetchUserEntries = async () => {
      const entriesSnapshot = await db
        .collection("entries")
        .where("user.id", "==", userData.id)
        .get();

      const userEntriesData = entriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUserEntries(userEntriesData);
    };

    fetchUserEntries();
  }, [userData.id]);

  return (
    <div className="card-container neon-block">
      <div className="card card-custom block">
        <div className="go-back" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>

        <div className="thirsty-profile-name">@{userData.display_name}</div>
        <div className="thirsty-profile-image">
          <img src={userData.profile_image_url} alt={userData.display_name} />
        </div>
        <div className="thirsty-profile-name">
          <img className="coins" src={Coin} alt="zvinci" />
          {userData.credits}
        </div>
        <h2 className="title">
          {userEntries.length > 0 ? "you are thirsty." : "not so thirsty."}
        </h2>
        {userEntries.length > 0 && (
          <small>
            All the songs you fed the <Icon preset={"small"} /> with:
          </small>
        )}
        <div className="list-entries">
          <div className="list-container">
            {userEntries.length === 0 && (
              <div className="no-entries">
                It seems that you need a bit of music in your life
              </div>
            )}
            {userEntries.map((entry) => (
              <div
                key={entry.id}
                className="list-item"
                onClick={() => window.open(entry.youtubeLink, "_blank")}
              >
                <div className="item-thumbnail list-item-columns-cell">
                  <img
                    src={entry.thumbnail}
                    alt={entry.title}
                    className="thumbnail"
                  />
                </div>
                <div className="item-title list-item-columns-cell">
                  {entry.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImThirsty;
