// useModal.js

import { useState, useCallback, useEffect } from "react";

export function useModal() {
  const [modalContent, setModalContent] = useState(null);
  const [modalCallbacks, setModalCallbacks] = useState([]);

  const openModal = (title, body) => {
    setModalContent({ title, body });
  };

  const closeModal = () => {
    setModalContent(null);
  };

  const registerCallback = useCallback((callback) => {
    setModalCallbacks((prevCallbacks) => [...prevCallbacks, callback]);

    // Unregister the callback when the component unmounts
    return () => {
      setModalCallbacks((prevCallbacks) =>
        prevCallbacks.filter((cb) => cb !== callback)
      );
    };
  }, []);

  // Effect to invoke registered callbacks when the modal content changes
  useEffect(() => {
    if (modalContent) {
      modalCallbacks.forEach((callback) => callback());
    }
  }, [modalContent, modalCallbacks]);

  return { openModal, closeModal, registerCallback };
}
