// useHoverEffect.js
import React, { useState } from "react";
import "./useHoverEffect.css";

const useHoverEffect = () => {
  const [hoveredId, setHoveredId] = useState(null);

  const handleMouseEnter = (entryId) => {
    setHoveredId(entryId);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const applyHoverEffect = (mainElement, replacementElement, entryId) => {
    const isHovered = hoveredId === entryId;

    return (
      <div
        className={`hover-container ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => handleMouseEnter(entryId)}
        onMouseLeave={handleMouseLeave}
      >
        {isHovered ? replacementElement : mainElement}
      </div>
    );
  };

  return { applyHoverEffect };
};

export default useHoverEffect;
