// Import the functions you need from the SDKs you need

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAMo_82rNUtHhVtoghaIZdOAxHi4BUituo",
  authDomain: "tap-thirsty.firebaseapp.com",
  projectId: "tap-thirsty",
  storageBucket: "tap-thirsty.appspot.com",
  messagingSenderId: "76711707363",
  appId: "1:76711707363:web:1ad1cea3938a0c0772fbd6",
  measurementId: "G-VCKQH8LR93",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default firebase;

export { db };
