// ModalContext.js
import React, { createContext, useContext } from "react";
import { useModal } from "./useModal";

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const modal = useModal();

  return (
    <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
  );
}

export function useModalContext() {
  return useContext(ModalContext);
}
