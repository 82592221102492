import React from "react";

import iconImage from "./images/whiteLogo.png";

const Icon = ({ preset, style }) => {
  // Preset configurations for size and display style
  const presets = {
    small: { size: 20, display: "inline-block" },
    medium: { size: 30, display: "inline-block" },
    large: { size: 40, display: "inline-block" },
    xlarge: { size: 60, display: "inline-block" },
    block: { size: 40, display: "block" },
  };

  const { size, display } = presets[preset] || presets.medium;

  return (
    <img
      src={iconImage}
      alt="Icon"
      style={{
        width: size,
        height: size,
        display,
        verticalAlign: "middle",
        lineHeight: "inherit",
        marginTop: "-2px",
        ...style,
      }}
    />
  );
};

export default Icon;
