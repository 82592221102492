import React, { useState, useEffect } from "react";
import { useTwitch } from "./TwitchContext";
import Switch from "react-switch";
import { db } from "./firebase";
import "./App.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faLink,
  faStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TapThirsty from "./images/tapThirstyV2.png";
import useHoverEffect from "./functionalities/useHoverEffect";

function Moderator(data) {
  const votingEnabled = data.votingEnabled;
  const { userData, setTwitchUserData } = useTwitch();
  const { applyHoverEffect } = useHoverEffect();
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [entryHistoryPositions, setEntryHistoryPositions] = useState([]);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [banInput, setBanInput] = useState("");
  const [votingStatusToggle, setVotingStatusToggle] = useState(votingEnabled);

  const notify = (message, type = null) => {
    if (type) toast[type](message);
    else toast(message);
  };

  const allowedUserIds = ["276658338", "427333201"];

  useEffect(() => {
    if (!allowedUserIds.includes(userData?.id)) {
      navigate("/");
    }
  }, [userData, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("twitchToken");
    setTwitchUserData(null);
    navigate("/");
  };

  useEffect(() => {
    const fetchData = async () => {
      const entriesSnapshot = await db.collection("entries").get();
      const entriesData = entriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEntries(entriesData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchBannedUsers = async () => {
      try {
        const bannedUsersSnapshot = await db.collection("bannedUsers").get();
        const bannedUsersData = bannedUsersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBannedUsers(bannedUsersData);
      } catch (error) {
        console.error("Error fetching banned users:", error);
      }
    };

    fetchBannedUsers();
  }, []);

  useEffect(() => {
    const fetchHistory = async () => {
      const historySnapshot = await db
        .collection("entryHistoryPositions")
        .get();
      const historyData = historySnapshot.docs.map((doc) => doc.data());
      setEntryHistoryPositions(historyData);
    };

    fetchHistory();
  }, []);

  const deleteAllSongs = async () => {
    if (
      window.confirm("Are you sure you want to reset and delete all songs?")
    ) {
      try {
        const batch = db.batch();

        // Save the top 10 entries to the "topEntries" collection
        const topEntriesSnapshot = await db
          .collection("entries")
          .orderBy("votes", "desc")
          .limit(10)
          .get();

        topEntriesSnapshot.forEach((doc) => {
          // Create a new document in "topEntries" collection with the same data
          const topEntriesRef = db.collection("top100Entries").doc(doc.id);
          batch.set(topEntriesRef, doc.data());
        });

        // Save all entries to the "allEntries" collection
        const allEntriesSnapshot = await db.collection("entries").get();
        allEntriesSnapshot.forEach((doc) => {
          // Create a new document in "allEntries" collection with the same data
          const allEntriesRef = db.collection("allEntries").doc(doc.id);
          batch.set(allEntriesRef, doc.data());
        });

        // Delete all documents from "entryHistoryPositions" collection
        const historySnapshot = await db
          .collection("entryHistoryPositions")
          .get();
        historySnapshot.forEach((doc) => batch.delete(doc.ref));

        // Delete all documents from "entryRankings" collection
        const rankingsSnapshot = await db.collection("entryRankings").get();
        rankingsSnapshot.forEach((doc) => batch.delete(doc.ref));

        // Delete all documents from "votes" collection
        const votesSnapshot = await db.collection("votes").get();
        votesSnapshot.forEach((doc) => batch.delete(doc.ref));

        // Delete all documents from "entries" collection
        const entriesSnapshot = await db.collection("entries").get();
        entriesSnapshot.forEach((doc) => batch.delete(doc.ref));

        // Commit the batched write operation
        await batch.commit();

        // Update the state to reflect the deletion
        setEntries([]);
        setEntryHistoryPositions([]);
        // You might need to update other state variables if applicable

        notify("All songs have been reset and deleted.", "success");
      } catch (error) {
        console.error("Error deleting songs:", error);
        notify("Error deleting songs. Please try again.", "error");
      }
    }
  };

  const calculateAveragePosition = (entryId) => {
    const entryHistory = entryHistoryPositions.filter(
      (history) => history.entryId === entryId
    );
    if (entryHistory.length === 0) {
      return null;
    }

    const totalPositions = entryHistory.reduce(
      (sum, history) => sum + history.position,
      0
    );
    return Math.round(totalPositions / entryHistory.length);
  };

  const getTendency = (currentPosition, averagePosition) => {
    if (!currentPosition || !averagePosition) return "same";
    if (currentPosition < averagePosition) {
      return "higher";
    } else if (currentPosition > averagePosition) {
      return "lower";
    } else {
      return "same";
    }
  };

  const checkAndUpdatePositions = async () => {
    const sortedEntries = [...entries].sort((a, b) => {
      if (b.votes !== a.votes) {
        return b.votes - a.votes;
      }
      return a.timestamp - b.timestamp;
    });

    sortedEntries.forEach(async (entry) => {
      const currentEntryHistory =
        entryHistoryPositions.find((history) => history.entryId === entry.id) ||
        [];
      const currentAveragePosition = calculateAveragePosition(entry.id);

      if (currentEntryHistory) {
        const currentPosition = sortedEntries.indexOf(entry) + 1;

        if (currentEntryHistory.position !== currentPosition) {
          const tendency = getTendency(currentPosition, currentAveragePosition);

          await db.collection("entryHistoryPositions").add({
            entryId: entry.id,
            timestamp: Date.now(),
            position: currentPosition,
            tendency,
          });

          setEntryHistoryPositions((prevPositions) => [
            ...prevPositions,
            {
              entryId: entry.id,
              timestamp: Date.now(),
              position: currentPosition,
              tendency,
            },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    checkAndUpdatePositions();

    if (entries.length && window.location.hash !== "") {
      setTimeout(() => {
        if (window.location.hash === "#newEntry") {
          window.scrollTo(0, document.body.scrollHeight);
          return;
        }
        const newEntryElement = document.getElementById(
          window.location.hash.slice(1)
        );
        if (newEntryElement) {
          newEntryElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  }, [entries]);

  const handleDeleteEntry = async (entryId, e) => {
    e.stopPropagation(); // Prevent the click event from triggering the song link
    if (window.confirm("Are you sure you want to delete this entry?")) {
      // Perform the deletion logic here
      await db.collection("entries").doc(entryId).delete();
      await db
        .collection("entryHistoryPositions")
        .where("entryId", "==", entryId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.delete();
          });
        });

      // Update the entries and history
      const updatedEntries = entries.filter((entry) => entry.id !== entryId);
      const updatedHistory = entryHistoryPositions.filter(
        (history) => history.entryId !== entryId
      );
      setEntries(updatedEntries);
      setEntryHistoryPositions(updatedHistory);
    }
  };

  const updateVotingStatusInDatabase = async (enabled) => {
    try {
      // Update the "votingStatus" field in the "settings" collection
      await db.collection("settings").doc("votingStatus").update({
        votingStatus: enabled,
      });

      // Update the global state
      setVotingStatusToggle(enabled);

      notify(
        `Voting has been ${enabled ? "enabled" : "disabled"} successfully.`,
        "success"
      );
    } catch (error) {
      console.error("Error updating voting status:", error);
      notify("Error updating voting status. Please try again.", "error");
    }
  };

  const handleToggleChange = (checked) => {
    // Update the local state immediately for a smooth UI update
    setVotingStatusToggle(checked);

    // Update the voting status in the database
    updateVotingStatusInDatabase(checked);
  };

  const updateCredits = async (userId, creditsToAdd) => {
    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      const currentCredits = userDoc.data().credits || 0;
      const newCredits = currentCredits + creditsToAdd;

      // Update user credits in the database
      await userRef.update({ credits: newCredits });
    }
  };

  const handleTopWinner = async () => {
    const topWinnerEntry = sortedEntries[0]; // Assuming the list is sorted
    if (topWinnerEntry) {
      await updateCredits(topWinnerEntry.user.id, 1000);
      notify("Top winner chosen successfully! 1000 Credits awarded.");
    } else {
      notify("No top winner found.", "warning");
    }
  };

  const handleChooseWinner = async (entryId, userId) => {
    // Update the winner field in the entries collection
    await db.collection("entries").doc(entryId).update({
      winner: true,
    });

    // Provide credits to the user who submitted the winning entry
    await updateCredits(userId, 500);

    // Update the entries state to reflect the winner
    setEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === entryId ? { ...entry, winner: true } : entry
      )
    );

    // Display a success message or perform any other necessary actions
    notify("Winner chosen successfully! 500 Credits awarded.");
  };

  const handleBanUser = async () => {
    if (banInput.trim() === "") {
      notify("Please enter a Twitch username.", "warning");
      return;
    }

    // Check if the user is already banned
    const isAlreadyBanned = bannedUsers.some(
      (user) => user.displayName === banInput
    );

    if (isAlreadyBanned) {
      notify("User is already banned.", "warning");
      return;
    }

    try {
      // Query the users collection to get the document ID for the given display name
      const userQuerySnapshot = await db
        .collection("users")
        .where("displayName", "==", banInput)
        .get();

      if (userQuerySnapshot.empty) {
        notify("User not found.", "warning");
        return;
      }

      const userId = userQuerySnapshot.docs[0].id;

      // Add the user to the banned users collection in the database
      await db.collection("bannedUsers").add({
        userId,
        displayName: banInput,
      });

      // Update the state to reflect the banned user
      setBannedUsers((prevBannedUsers) => [
        ...prevBannedUsers,
        { userId, displayName: banInput },
      ]);

      notify(`${banInput} has been banned successfully.`, "success");
      setBanInput(""); // Clear the input field
    } catch (error) {
      console.error("Error banning user:", error);
      notify("Error banning user. Please try again.", "error");
    }
  };

  const unbanUser = async (userId, name) => {
    try {
      // Remove the user from the banned users list in the database
      await db.collection("bannedUsers").doc(userId).delete();

      // Remove the user from the component state
      setBannedUsers((prevBannedUsers) =>
        prevBannedUsers.filter((user) => user.id !== userId)
      );

      notify(`User with ID ${name} has been unbanned.`, "success");
    } catch (error) {
      console.error("Error unbanning user:", error);
      notify("Error unbanning user. Please try again.", "error");
    }
  };

  const sortedEntries = [...entries].sort((a, b) => {
    // Sort by votes first
    if (b.votes !== a.votes) {
      return b.votes - a.votes;
    }

    // If votes are the same, sort by timestamp (time they were added)
    return a.timestamp - b.timestamp;
  });

  return (
    <div className="card-container neon-block">
      <div className="card card-custom block">
        <div className="go-back" onClick={() => navigate("/")}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <span className="rainbow"></span>
        <h2 className="title">
          <img src={TapThirsty} alt="TapThirsty" />
        </h2>
        <h5 className="subtitle">Moderator Panel</h5>
        <div className="voting-toggle">
          <label>Voting Status</label>
          <Switch
            checked={votingStatusToggle}
            onChange={handleToggleChange}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={24}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={16}
            width={40}
            className="react-switch"
            id="material-switch"
          />
        </div>
        <button className="btn-top-winner" onClick={handleTopWinner}>
          Top Winner
        </button>
        <button className="btn-delete-all" onClick={deleteAllSongs}>
          RESET AND DELETE ALL SONGS
        </button>

        <div className="ban-user-section">
          <input
            type="text"
            placeholder="Enter Twitch Username or User ID"
            value={banInput}
            onChange={(e) => setBanInput(e.target.value)}
          />
          <button onClick={handleBanUser}>Ban User</button>
        </div>
        <div className="banned-users-list">
          <h3>Banned Users</h3>
          <ul>
            {bannedUsers.map((user) => (
              <li key={user.id}>
                {user.displayName}{" "}
                <button onClick={() => unbanUser(user.id, user.displayName)}>
                  Unban
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="scroll-to-bottom"
          onClick={() => window.scrollTo(0, document.body.scrollHeight)}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        <div className="list-entries">
          <div className="list-container">
            {sortedEntries.map((entry, index) => {
              const currentRank =
                entries.findIndex((e) => e.id === entry.id) + 1;
              const averagePosition = calculateAveragePosition(entry.id);
              const tendency = getTendency(currentRank, averagePosition);
              return (
                <div key={entry.id} id={entry.id}>
                  {index % 10 === 0 && index !== 0 && (
                    <div className="separator">
                      <h4>top {index}.</h4>
                    </div>
                  )}
                  <div
                    className="list-item-container"
                    onClick={() => window.open(entry.youtubeLink, "_blank")}
                  >
                    <span>
                      <strong>{index + 1}</strong>
                    </span>
                    <div
                      className={`list-item ${
                        window.location.hash === "#" + entry.id
                          ? "selected"
                          : ""
                      }`}
                    >
                      <div className="item-thumbnail list-item-columns-cell">
                        <img
                          src={entry.thumbnail}
                          alt={entry.title}
                          className="thumbnail"
                        />
                      </div>
                      <div className="item-title list-item-columns-cell">
                        {entry.user &&
                          applyHoverEffect(
                            entry.title,
                            entry.user.displayName,
                            entry.id
                          )}
                      </div>
                      <div className="item-share list-item-columns-cell">
                        <FontAwesomeIcon
                          icon={faLink}
                          onClick={(e) => {
                            e.stopPropagation();
                            const link =
                              window.location.hostname +
                              "/songs-list#" +
                              entry.id;
                            navigator.clipboard.writeText(link).then(
                              function () {
                                alert(
                                  "Async: Copying to clipboard was successful!"
                                );
                              },
                              function (err) {
                                alert("Async: Could not copy text: ", err);
                              }
                            );
                          }}
                        />
                        {/* {new Date(YTDurationToSeconds(entry.duration) * 1000)
                          .toISOString()
                          .slice(14, 19)} */}
                      </div>
                      <div className="item-delete list-item-columns-cell">
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={(e) => handleDeleteEntry(entry.id, e)}
                        />
                      </div>
                      <div className="item-choose-winner list-item-columns-cell">
                        <FontAwesomeIcon
                          icon={faStar}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChooseWinner(entry.id, entry.user.id);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <button className="btn-logout" onClick={handleLogout}>
        Logout
      </button>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default Moderator;
