import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faChevronCircleUp,
  faChevronCircleDown,
  faChevronUp,
  faChevronLeft,
  faCaretUp,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as faThumbsUpRegular } from "@fortawesome/free-regular-svg-icons/faThumbsUp";
import Coin from "./images/coinLogo.png";
import { useTwitch } from "./TwitchContext";
import { db } from "./firebase"; // Assuming you have a `db` export from your firebase file
import Icon from "./TapIcon";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Helmet } from "react-helmet";
import { isSameDay } from "date-fns";

// ShowcasePage component
const ShowcasePage = (data) => {
  const votingEnabled = data.votingEnabled;
  const { userData, setTwitchUserData } = useTwitch();
  const navigate = useNavigate();
  const notify = (message, type = null) => {
    if (type) toast[type](message);
    else toast(message);
  };

  const [entry, setEntry] = useState(null);
  const [prevEntry, setPrevEntry] = useState(null);
  const [nextEntry, setNextEntry] = useState(null);
  const [entryPosition, setEntryPosition] = useState(null);
  const [sortedEntries, setSortedEntries] = useState([]);
  const [entryHistoryPositions, setEntryHistoryPositions] = useState([]);
  const [currentVoteHistory, setCurrentVoteHistory] = useState([]);
  const [userCredits, setUserCredits] = useState(userData.credits || 0); // State to hold user credits
  const [currentVideoIndex, setCurrentVideoIndex] = useState(null);

  const { entryId } = useParams();

  useEffect(() => {
    const fetchEntryDetails = async () => {
      try {
        // Fetch the current entry based on entryid
        const entryDoc = await db.collection("entries").doc(entryId).get();

        if (entryDoc.exists) {
          const currentEntry = { id: entryDoc.id, ...entryDoc.data() };
          setEntry(currentEntry);

          // Fetch all entries ordered by votes and timestamp
          // Fetch all entries ordered by votes and timestamp with pagination
          const entrySnapshot = await db
            .collection("entries") // Set the limit to the number of entries you want to fetch
            .get();

          let entryList = entrySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          entryList = [...entryList].sort((a, b) => {
            // Sort by votes first
            if (b.votes !== a.votes) {
              return b.votes - a.votes;
            }

            // If votes are the same, sort by timestamp (time they were added)
            return a.timestamp - b.timestamp;
          });

          setSortedEntries(entryList);

          const index = entryList.findIndex((e) => e.id === currentEntry.id);
          setCurrentVideoIndex(index);

          // Find the entry's current position
          const currentPosition = entryList.findIndex(
            (entry) => entry.id === entryId
          );

          setEntryPosition(currentPosition + 1);

          // Fetch the previous entry
          const prevEntry =
            currentPosition > 0 ? entryList[currentPosition - 1] : null;
          setPrevEntry(prevEntry);

          // Fetch the next entry
          const nextEntry =
            currentPosition < entryList.length - 1
              ? entryList[currentPosition + 1]
              : null;
          setNextEntry(nextEntry);
        } else {
          // Handle case where entry with entryid does not exist
          notify("Entry not found.");
          navigate("/"); // Redirect to home or another page
        }
      } catch (error) {
        console.error("Error fetching entry details:", error);
        notify("Error fetching entry details. Try again later.");
        navigate("/"); // Redirect to home or another page
      }
    };

    fetchEntryDetails();
  }, [entryId, navigate]);

  useEffect(() => {
    // Function to fetch user vote history
    const fetchUserVoteHistory = async () => {
      const userId = userData.id;
      const today = new Date().toISOString().split("T")[0];

      // Query the database for user vote history on the current day
      const userVoteHistoryRef = db
        .collection("entryVotes")
        .where("userId", "==", userId);
      const userVoteHistorySnapshot = await userVoteHistoryRef.get();

      // Create a map to store vote history for the current day
      const todayVoteHistory = {};

      userVoteHistorySnapshot.forEach((doc) => {
        const entryId = doc.data().entryId;
        const voteTimestamp = doc.data().timestamp;

        // Check if the vote is on the current day
        if (new Date(voteTimestamp).toISOString().split("T")[0] === today) {
          todayVoteHistory[entryId] = voteTimestamp;
        }
      });

      // Update the state with the user's vote history for the current day
      setCurrentVoteHistory(todayVoteHistory);
    };

    // Call the function to fetch user vote history
    fetchUserVoteHistory();

    // Rest of your existing code...
  }, [entryId]);

  useEffect(() => {
    const fetchHistory = async () => {
      const historySnapshot = await db
        .collection("entryHistoryPositions")
        .get();
      const historyData = historySnapshot.docs.map((doc) => doc.data());
      setEntryHistoryPositions(historyData);
    };

    fetchHistory();
  }, []);

  // Function to handle voting
  const handleVote = async () => {
    const entryId = entry.id;
    const userId = userData.id;

    // Check if the user has already voted today for the specific entry
    const today = new Date().toISOString().split("T")[0];
    const userVoteRef = db.collection("entryVotes").doc(`${userId}_${entryId}`);
    const userVoteDoc = await userVoteRef.get();

    // Remove the check for existing userVoteDoc to allow multiple votes
    // if (userVoteDoc.exists) {
    //   notify(
    //     "You already voted for this entry today, see ya tomorrow!!!",
    //     "warning"
    //   );
    //   return;
    // }

    const entryRef = db.collection("entries").doc(entryId);
    const entryDoc = await entryRef.get();

    if (entryDoc.exists) {
      // Check if the user has already voted today for any entry
      const userVotesRef = db
        .collection("entryVotes")
        .where("userId", "==", userId);
      const userVotesSnapshot = await userVotesRef.get();

      const hasVotedToday = userVotesSnapshot.docs.some((doc) =>
        isSameDay(doc.data().timestamp, new Date())
      );

      const currentVotes = entryDoc.data().votes || 0;

      const voteCost = 200;

      const userRef = db.collection("users").doc(userId);
      const userDoc = await userRef.get();

      if (
        currentVoteHistory[entryId] &&
        isSameDay(currentVoteHistory[entryId], new Date())
      ) {
        notify("You already voted for this song today!", "warning");
        return;
      }

      if (userDoc.exists) {
        const userCredits = userDoc.data().credits || 0;

        if (hasVotedToday && userCredits < voteCost) {
          notify("Insufficient credits to vote. Earn more credits!", "warning");
          return;
        }

        // If it's not the first vote and the user has enough credits, update credits
        if (hasVotedToday) {
          // Update user credits
          updateCredits(userId, -voteCost);
          // Update the state variable for user credits
          setUserCredits((prevCredits) => prevCredits - voteCost);
        }

        // Record the user's vote for the day and entry
        await userVoteRef.set({
          userId,
          entryId,
          timestamp: Date.now(),
        });

        // Update the UI by updating the state
        setCurrentVoteHistory((prevEntries) => ({
          ...prevEntries,
          [entryId]: Date.now(),
        }));

        // Update the entry's votes in the database
        entryRef.update({
          votes: currentVotes + 1,
        });

        const entryUserName = entryDoc.data().user.displayName;

        if (!hasVotedToday) {
          // Update user credits
          updateCredits(userId, 100);
          // Update the state variable for user credits
          setUserCredits((prevCredits) => prevCredits + 100);
          // Display a success message or update UI as needed
          notify(
            <>
              Voted successfully! You have the same music taste as{" "}
              <strong>{entryUserName}</strong>. 100 Credits Received
            </>,
            "success"
          );
        } else {
          // Display a success message or update UI as needed
          notify(
            <>
              Voted successfully! You have the same music taste as{" "}
              <strong>{entryUserName}</strong>. 200 Credits Consumed
            </>,
            "success"
          );
        }
      }
    }
  };

  // Function to copy URL to clipboard
  const copyUrlToClipboard = (e, entryId, youtubeLink = null) => {
    e.preventDefault();

    if (youtubeLink) {
      try {
        const shareLink = youtubeLink;
        navigator.clipboard.writeText(shareLink);
        notify("Youtube URL copied to clipboard!", "success");
      } catch (error) {
        console.error("Error copying URL to clipboard:", error);
        notify("Error copying URL to clipboard. Try again later.", "error");
      }
    } else {
      try {
        const shareLink = `https://${window.location.hostname}/showcase/${entryId}`;
        navigator.clipboard.writeText(shareLink);
        notify("Vote URL copied to clipboard!", "success");
      } catch (error) {
        console.error("Error copying URL to clipboard:", error);
        notify("Error copying URL to clipboard. Try again later.", "error");
      }
    }
  };

  // Function to handle video end
  const handleVideoEnd = () => {
    if (!currentVideoIndex) {
      const index = sortedEntries.findIndex((e) => e.id === entry.id);
      navigate(`/showcase/${sortedEntries[index - 1].id}`);
      setCurrentVideoIndex(index - 1);
    }
    // Check if there is a next video
    if (currentVideoIndex && currentVideoIndex < sortedEntries.length - 1) {
      // Go to the next video

      console.log(currentVideoIndex);
      navigate(`/showcase/${sortedEntries[currentVideoIndex - 1].id}`);
      setCurrentVideoIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Function to update user credits
  const updateCredits = async (userId, creditsToAdd) => {
    try {
      const userRef = db.collection("users").doc(userId);
      const userDoc = await userRef.get();

      if (userDoc.exists) {
        const currentCredits = userDoc.data().credits || 0;
        const newCredits = currentCredits + creditsToAdd;

        // Update user credits in the database
        await userRef.update({ credits: newCredits });
      }
    } catch (error) {
      console.error("Error updating user credits:", error);
      notify("Error updating user credits. Try again later.", "error");
    }
  };

  const calculateAveragePosition = (entryId) => {
    const entryHistory = entryHistoryPositions.filter(
      (history) => history.entryId === entryId
    );
    if (entryHistory.length === 0) {
      return null;
    }

    const totalPositions = entryHistory.reduce(
      (sum, history) => sum + history.position,
      0
    );
    return Math.round(totalPositions / entryHistory.length);
  };

  const getTendency = (currentPosition, averagePosition) => {
    if (!currentPosition || !averagePosition) return "same";
    if (currentPosition < averagePosition) {
      return "higher";
    } else if (currentPosition > averagePosition) {
      return "lower";
    } else {
      return "same";
    }
  };

  const currentRank = sortedEntries.findIndex((e) => e.id === entryId) + 1;
  const averagePosition = calculateAveragePosition(entryId);
  const tendency = getTendency(currentRank, averagePosition);

  const userVotedToday =
    entry && currentVoteHistory[entry.id]
      ? isSameDay(new Date(currentVoteHistory[entry.id]), new Date())
      : false;

  const vidRef = useRef();

  useEffect(() => {
    if (vidRef && vidRef.current) {
      vidRef.current.play();
      vidRef.current.muted = false;
    }
  }, []);
  // Render component
  return (
    <div className="showcase-container">
      {entryId && entry && entry.title && (
        <Helmet>
          <meta
            property="og:url"
            content={`https://${window.location.hostname}/showcase/${entryId}`}
          />
          <meta property="og:type" content="music" />
          <meta
            property="og:title"
            content={"Tap Thirsty - " + entry.title + " | Alin Zvinca"}
          />
          <meta
            property="og:description"
            content={"Vote the song " + entry.title + " on Tap Thirsty!"}
          />
          <meta property="og:image" content={entry.thumbnail} />
        </Helmet>
      )}
      {/* Row Above: Entry Rank Above */}
      {prevEntry && (
        <div
          className="list-item-container above-entry"
          onClick={() => navigate("/showcase/" + prevEntry.id)}
        >
          <div className={`list-item`}>
            <div className="item-controls list-item-columns-cell">
              {entryPosition - 1}
            </div>
            <div className="item-thumbnail list-item-columns-cell">
              <img
                src={prevEntry.thumbnail}
                alt={prevEntry.title}
                className="thumbnail"
              />
            </div>
            <div className="item-title list-item-columns-cell">
              {prevEntry.title}
            </div>
          </div>
        </div>
      )}

      {/* Main Container: Showcase Entry */}
      <div className="card-container neon-block">
        <div className="card card-custom block">
          <span className="rainbow"></span>
          {entry == null && (
            <div className="loading">
              <Skeleton height={"70%"} width={"100%"} borderRadius={10} />
              <Skeleton
                height={"15%"}
                style={{ marginTop: "15px" }}
                borderRadius={10}
              />
            </div>
          )}
          {entry && (
            <>
              {/* Display the player for the YouTube link */}
              {/* Implement YouTube player or use an embed component */}
              <div className="player-container">
                {/* Example: Use an embedded YouTube player */}
                <ReactPlayer
                  url={entry.youtubeLink}
                  width="100%"
                  height="100%"
                  controls={true}
                  onEnded={handleVideoEnd} // Call handleVideoEnd when the video ends
                  playing={true}
                  playsinline={true}
                  playsInline={true}
                  autoplay={true}
                  ref={vidRef}
                />
              </div>
              <h7 className="showcase-title">
                <strong>{entry.title}</strong>
              </h7>

              <h1 className="text-neon">
                <span className="letter letter-1">P</span>
                <span className="letter letter-2">O</span>
                <span className="letter letter-3">S</span>
                <span className="letter letter-4">.{entryPosition}</span>
              </h1>

              {/* Display vote button */}
              <div className="control-buttons item-controls">
                <div
                  className="back"
                  onClick={(e) => {
                    navigate("/songs-list");
                  }}
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="xl" />
                </div>
                <div className="vote-controls">
                  {/* Display tendency */}
                  <div className="tendency">
                    {tendency === "higher" && entry.votes !== 0 && (
                      <>
                        <FontAwesomeIcon
                          icon={faChevronCircleUp}
                          size="xl"
                          className="up"
                          data-tooltip-id="tendency-up"
                          data-tooltip-place="top"
                        />
                        <Tooltip
                          id="tendency-up"
                          variant="light"
                          content={
                            <span>
                              The tendency for this song is{" "}
                              <strong>ascendant</strong>! The vote button is
                              over there, on the left!
                            </span>
                          }
                          style={{
                            zIndex: 9999,
                            maxWidth: "250px",
                            lineHeight: "normal",
                          }}
                        />
                      </>
                    )}

                    {tendency === "lower" && entry.votes !== 0 && (
                      <>
                        <FontAwesomeIcon
                          icon={faChevronCircleDown}
                          size="xl"
                          className="down"
                          data-tooltip-id="tendency-down"
                          data-tooltip-place="top"
                        />
                        <Tooltip
                          id="tendency-down"
                          variant="light"
                          content={
                            <span>
                              The tendency for this song is{" "}
                              <strong>descendant</strong>! The vote button is
                              over there, on the left!
                            </span>
                          }
                          style={{
                            zIndex: 9999,
                            maxWidth: "250px",
                            lineHeight: "normal",
                          }}
                        />
                      </>
                    )}
                  </div>
                  {/* Copy URL button */}
                  {votingEnabled && (
                    <div
                      className="upvote"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleVote(entry.id);
                      }}
                    >
                      {userVotedToday ? (
                        <FontAwesomeIcon icon={faThumbsUp} size="xl" />
                      ) : (
                        <FontAwesomeIcon icon={faThumbsUpRegular} size="xl" />
                      )}
                    </div>
                  )}
                  <div
                    onClick={() => navigate("/im-thirsty")}
                    className="showcase-coins"
                  >
                    <img className="coins" src={Coin} alt="zvinci" />
                    {userCredits}
                  </div>
                </div>

                <div className="other-controls">
                  <div className="item-share list-item-columns-cell">
                    <FontAwesomeIcon
                      icon={faLink}
                      onClick={(e) => copyUrlToClipboard(e, entry.id)}
                    />
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size="xl"
                      onClick={(e) => window.open(entry.youtubeLink, "_blank")}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* Row Below: Entry Rank Below */}
      {nextEntry && (
        <div
          className="list-item-container below-entry"
          onClick={() => navigate("/showcase/" + nextEntry.id)}
        >
          <div className={`list-item`}>
            <div className="item-controls list-item-columns-cell">
              {entryPosition + 1}
            </div>
            <div className="item-thumbnail list-item-columns-cell">
              <img
                src={nextEntry.thumbnail}
                alt={nextEntry.title}
                className="thumbnail"
              />
            </div>
            <div className="item-title list-item-columns-cell">
              {nextEntry.title}
            </div>
          </div>
        </div>
      )}

      {/* Toast notifications */}
      <ToastContainer theme="dark" />
    </div>
  );
};

export default ShowcasePage;
