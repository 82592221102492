// src/SongsList.js
import React, { useState, useEffect } from "react";
import { useTwitch } from "./TwitchContext";
import { db } from "./firebase"; // Assuming you have a `db` export from your firebase file
import "./App.css";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { YTDurationToSeconds } from "./functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronCircleDown,
  faChevronCircleUp,
  faDesktop,
  faLink,
  faPlus,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";

import { faThumbsUp as faThumbsUpRegular } from "@fortawesome/free-regular-svg-icons/faThumbsUp";

import Coin from "./images/coinLogo.png";
import TapThirsty from "./images/tapThirstyV2.png";
import Icon from "./TapIcon";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { isSameDay } from "date-fns";
import { Helmet } from "react-helmet";

// Set your desired limit (e.g., 3 entries per week)
const weeklyEntryLimit = 3;

function SongsList(data) {
  const votingEnabled = data.votingEnabled;
  const { userData, setTwitchUserData } = useTwitch();
  const navigate = useNavigate();
  const notify = (message, type = null) => {
    if (type) toast[type](message);
    else toast(message);
  };

  const [entries, setEntries] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [videoInfo, setVideoInfo] = useState(null);
  const [entryHistoryPositions, setEntryHistoryPositions] = useState([]);
  const [devicePerformance, setDevicePerformance] = useState(null);
  const [weeklySubmitted, setWeeklySubmitted] = useState(3);
  const [currentVoteHistory, setCurrentVoteHistory] = useState([]);
  const [userCredits, setUserCredits] = useState(userData.credits || 0); // State to hold user credits

  useEffect(() => {
    const checkPerformance = () => {
      if (window.performance) {
        const navigationTiming =
          window.performance.getEntriesByType("navigation")[0];

        if (navigationTiming) {
          // Calculate some performance metric, e.g., load time
          const loadTime = navigationTiming.domComplete;

          // You can adjust this threshold based on your application's requirements
          const performanceThreshold = 900; // Example threshold in milliseconds

          // Set a degree based on the threshold
          if (loadTime < performanceThreshold) {
            setDevicePerformance(10); // Good performance
          } else {
            setDevicePerformance(5); // Moderate performance
          }
        }
      }
    };

    checkPerformance();
  }, []);

  useEffect(() => {
    // Update the user credits when the userData changes
    setUserCredits(userData.credits || 0);
  }, [userData]);

  const handleLogout = () => {
    if (
      window.confirm(
        "Are you sure you want to leave? We so need your songs and votes :("
      )
    ) {
      // Clear user data and token from storage

      localStorage.removeItem("twitchToken");
      setTwitchUserData(null);
      navigate("/");
    }
  };

  useEffect(() => {
    // Fetch entries and historical rankings from the database
    const fetchData = async () => {
      const entriesSnapshot = await db.collection("entries").get();
      const entriesData = entriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEntries(entriesData);
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Function to fetch user vote history
    const fetchUserVoteHistory = async () => {
      const userId = userData.id;
      const today = new Date().toISOString().split("T")[0];

      // Query the database for user vote history on the current day
      const userVoteHistoryRef = db
        .collection("entryVotes")
        .where("userId", "==", userId);
      const userVoteHistorySnapshot = await userVoteHistoryRef.get();

      // Create a map to store vote history for the current day
      const todayVoteHistory = {};

      userVoteHistorySnapshot.forEach((doc) => {
        const entryId = doc.data().entryId;
        const voteTimestamp = doc.data().timestamp;

        // Check if the vote is on the current day
        if (new Date(voteTimestamp).toISOString().split("T")[0] === today) {
          todayVoteHistory[entryId] = voteTimestamp;
        }
      });

      // Update the state with the user's vote history for the current day
      setCurrentVoteHistory(todayVoteHistory);
    };

    // Call the function to fetch user vote history
    fetchUserVoteHistory();

    // Rest of your existing code...
  }, [entries]);

  const handleOpenModalAddSong = async () => {
    const userId = userData.id;

    // Get the start of the current week
    const startOfWeek = getStartOfWeek();

    // Check the number of entries the user has made this week
    const userEntriesRef = db
      .collection("entries")
      .where("user.id", "==", userId)
      .where("timestamp", ">=", startOfWeek.getTime());

    const userEntriesSnapshot = await userEntriesRef.get();

    // If the limit is reached, prevent adding a new entry
    if (userEntriesSnapshot.size >= weeklyEntryLimit) {
      notify("Weekly entry limit reached. Cannot add more entries this week.");
      return;
    }

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      const historySnapshot = await db
        .collection("entryHistoryPositions")
        .get();
      const historyData = historySnapshot.docs.map((doc) => doc.data());
      setEntryHistoryPositions(historyData);
    };

    fetchHistory();
  }, []);

  const calculateAveragePosition = (entryId) => {
    const entryHistory = entryHistoryPositions.filter(
      (history) => history.entryId === entryId
    );
    if (entryHistory.length === 0) {
      return null;
    }

    const totalPositions = entryHistory.reduce(
      (sum, history) => sum + history.position,
      0
    );
    return Math.round(totalPositions / entryHistory.length);
  };

  const getTendency = (currentPosition, averagePosition) => {
    if (!currentPosition || !averagePosition) return "same";
    if (currentPosition < averagePosition) {
      return "higher";
    } else if (currentPosition > averagePosition) {
      return "lower";
    } else {
      return "same";
    }
  };

  const checkAndUpdatePositions = async () => {
    const sortedEntries = entries
      ? [...entries].sort((a, b) => {
          // Sort by votes first
          if (b.votes !== a.votes) {
            return b.votes - a.votes;
          }

          // If votes are the same, sort by timestamp (time they were added)
          return a.timestamp - b.timestamp;
        })
      : null;

    if (sortedEntries)
      sortedEntries.forEach(async (entry) => {
        const currentEntryHistory =
          entryHistoryPositions.find(
            (history) => history.entryId === entry.id
          ) || [];
        const currentAveragePosition = calculateAveragePosition(entry.id);

        if (currentEntryHistory) {
          const currentPosition = sortedEntries.indexOf(entry) + 1;

          // Check if the position has changed
          if (currentEntryHistory.position !== currentPosition) {
            const tendency = getTendency(
              currentPosition,
              currentAveragePosition
            );

            await db.collection("entryHistoryPositions").add({
              entryId: entry.id,
              timestamp: Date.now(),
              position: currentPosition,
              tendency,
            });

            // Update the entryHistoryPositions state
            setEntryHistoryPositions((prevPositions) => [
              ...prevPositions,
              {
                entryId: entry.id,
                timestamp: Date.now(),
                position: currentPosition,
                tendency,
              },
            ]);
          }
        }
      });
  };

  useEffect(() => {
    checkAndUpdatePositions();
    getWeeklyEntriesRemaining();

    // Check for the hash and scroll to the element
    if (entries && entries.length && window.location.hash !== "") {
      setTimeout(() => {
        if (window.location.hash === "#newEntry") {
          window.scrollTo(0, document.body.scrollHeight);
          return;
        }
        const newEntryElement = document.getElementById(
          window.location.hash.slice(1)
        ); // Assuming you have an element with the id "newEntry"
        if (newEntryElement) {
          newEntryElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  }, [entries]);

  // Helper function to get the start of the current week
  const getStartOfWeek = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(today.getDate() - today.getDay());
    return startOfWeek;
  };

  const handleAddSong = async () => {
    // Assuming you have the user ID available in userData
    const userId = userData.id;

    // Get the start of the current week
    const startOfWeek = getStartOfWeek();

    // Check the number of entries the user has made this week
    const userEntriesRef = db
      .collection("entries")
      .where("user.id", "==", userId)
      .where("timestamp", ">=", startOfWeek.getTime());

    const userEntriesSnapshot = await userEntriesRef.get();

    // If the limit is reached, prevent adding a new entry
    if (userEntriesSnapshot.size >= weeklyEntryLimit) {
      notify(
        "Weekly entry limit reached. Cannot add more entries this week.",
        "warning"
      );
      return;
    }
    // Check the current number of entries
    const currentEntryCount = entries.length;

    // Set your desired limit (e.g., 30)
    const entryLimit = 30;

    if (!youtubeLink) {
      notify(
        <>
          Don't be nasty, don't tease the <Icon preset="small" />
          Feed it with a link please!
        </>,
        "warning"
      );

      return;
    }

    // Clean the YouTube link to extract the video ID
    const url = new URL(youtubeLink);

    // Extract the video ID from the searchParams
    let videoId = url.searchParams.get("v");

    if (!videoId) {
      videoId = youtubeLink.split(".be/")[1];
      videoId = videoId.split("?")[0];

      if (!videoId) {
        notify(
          "It seems that this 'Youtube' link does not have a video ID.",
          "warning"
        );

        return;
      }
    }

    // Check if the video ID already exists in entries
    const duplicateEntryInEntries = entries.find(
      (entry) => entry.videoId === videoId
    );

    // Check if the video ID already exists in allEntries
    const allEntriesRef = db.collection("allEntries");
    const duplicateEntryInAllEntries = await allEntriesRef
      .where("videoId", "==", videoId)
      .get();

    if (duplicateEntryInEntries) {
      notify("Duplicate entry: This song is already in the list.", "warning");
      return;
    }

    if (!duplicateEntryInAllEntries.empty) {
      notify(
        "Duplicate entry: This song was previously used in a Tap Thirsty show!",
        "warning"
      );
      return;
    }

    // If the user has enough credits, allow entry submission even if the limit is reached
    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      const userCredits = userDoc.data().credits || 0;

      // Set your credit threshold for submitting entries without limit
      const creditThreshold = 1600;

      if (currentEntryCount >= entryLimit) {
        if (userCredits >= creditThreshold) {
          // If the limit is reached, replace the entry with the least votes
          if (currentEntryCount >= entryLimit) {
            const entryWithLeastVotes = entries.reduce((prev, current) =>
              prev.votes < current.votes ? prev : current
            );

            // Remove the entry with the least votes
            await db.collection("entries").doc(entryWithLeastVotes.id).delete();

            // Update user credits
            updateCredits(userId, -1600);

            // Alert the user about the replacement
            notify(
              `Your entry has replaced the song "${entryWithLeastVotes.title}" with the least votes.`
            );
          }
        } else {
          notify(
            "Insufficient credits to add a new entry. Tap Thirsty comes from THIRTY, got it? THIRSTY, THIRTY... Thirty songs is the limit if you do not have 1600 credits.",
            "warning"
          );
          return;
        }
      }
    } else {
      return;
    }
    // Add the new entry to the database
    db.collection("entries")
      .add({
        title: videoInfo.title,
        duration: videoInfo.duration,
        channelName: videoInfo.channelName,
        thumbnail: videoInfo.thumbnailUrl,
        youtubeLink,
        videoId, // Store the video ID
        user: {
          id: userData.id,
          displayName: userData.display_name,
          avatar: userData.profile_image_url,
        },
        votes: 0,
        timestamp: Date.now(),
      })
      .then(async () => {
        await updateCredits(userData.id, 300);
        window.location.hash = "newEntry";

        // Reload the page after adding the song
        window.location.reload();
      })
      .catch((error) => {
        notify("Error adding song, try again later");
      });
  };

  const getWeeklyEntriesRemaining = async () => {
    const userId = userData.id;

    // Get the start of the current week
    const startOfWeek = getStartOfWeek();
    const userEntriesRef = db
      .collection("entries")
      .where("user.id", "==", userId)
      .where("timestamp", ">=", startOfWeek.getTime());

    const userEntriesSnapshot = await userEntriesRef.get();

    // If the limit is reached, prevent adding a new entry
    if (userEntriesSnapshot.size >= weeklyEntryLimit) {
      setWeeklySubmitted(false);
      return;
    }

    setWeeklySubmitted(weeklyEntryLimit - userEntriesSnapshot.size);
  };

  const updateCredits = async (userId, creditsToAdd) => {
    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      const currentCredits = userDoc.data().credits || 0;
      const newCredits = currentCredits + creditsToAdd;

      // Update user credits in the database
      await userRef.update({ credits: newCredits });
    }
  };

  const handleYoutubeLinkChange = (event) => {
    const link = event.target.value;
    setYoutubeLink(link);

    // Fetch video info when a valid YouTube link is provided
    // This is a simplified check, you might want to improve it
    if (link.includes("youtube.com") || link.includes("youtu.be")) {
      // You can use an external service or your own API to get video information based on the YouTube link
      // Here, we assume the link is in the format "https://www.youtube.com/watch?v=VIDEO_ID"
      let videoId = link.split("v=")[1];
      if (!videoId) {
        videoId = link.split(".be/")[1];
        videoId = videoId.split("?")[0];
        if (!videoId) {
          notify("Incorrect Youtube video link!", "warning");
          return;
        }
      }

      const apiKey = "AIzaSyDA4Zn-63m5pu7ppmZzSJymT1PYQ_39WOs";
      const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,contentDetails&key=${apiKey}`;

      // Fetch video information
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const videoSnippet = data.items[0].snippet;
          const duration = data.items[0].contentDetails.duration;

          const ytDuration = YTDurationToSeconds(duration);

          if (ytDuration > 5 * 60) {
            notify(
              "The song you sent is longer than 5 minutes. Please choose....... a song!",
              "error"
            );
            return;
          }

          setVideoInfo({
            title: videoSnippet.title,
            channelName: videoSnippet.channelTitle,
            duration,
            thumbnailUrl: videoSnippet.thumbnails.high.url,
          });
        })
        .catch((error) => {
          console.error("Error fetching YouTube video info");
        });
    } else {
      // Reset video info if the link is not valid
      setVideoInfo(null);
    }
  };
  // Sort entries based on votes in descending order
  const sortedEntries = entries
    ? [...entries].sort((a, b) => {
        // Sort by votes first
        if (b.votes !== a.votes) {
          return b.votes - a.votes;
        }

        // If votes are the same, sort by timestamp (time they were added)
        return a.timestamp - b.timestamp;
      })
    : null;

  const copyShareLink = (e, entryId) => {
    e.stopPropagation();
    const link = "https://" + window.location.hostname + "/showcase/" + entryId;
    navigator.clipboard.writeText(link).then(
      function () {
        notify("The link was coppied to clipboard!");
      },
      function (err) {
        notify("Couldn't copy the link to clipboard. Try again!");
      }
    );
  };

  const handleVote = async (entryId) => {
    const userId = userData.id;

    // Check if the user has already voted today for the specific entry
    const today = new Date().toISOString().split("T")[0];
    const userVoteRef = db.collection("entryVotes").doc(`${userId}_${entryId}`);
    const userVoteDoc = await userVoteRef.get();

    // Remove the check for existing userVoteDoc to allow multiple votes
    // if (userVoteDoc.exists) {
    //   notify(
    //     "You already voted for this entry today, see ya tomorrow!!!",
    //     "warning"
    //   );
    //   return;
    // }

    const entryRef = db.collection("entries").doc(entryId);
    const entryDoc = await entryRef.get();

    if (entryDoc.exists) {
      // Check if the user has already voted today for any entry
      const userVotesRef = db
        .collection("entryVotes")
        .where("userId", "==", userId);
      const userVotesSnapshot = await userVotesRef.get();

      const hasVotedToday = userVotesSnapshot.docs.some((doc) =>
        isSameDay(doc.data().timestamp, new Date())
      );

      const currentVotes = entryDoc.data().votes || 0;

      const voteCost = 200;

      const userRef = db.collection("users").doc(userId);
      const userDoc = await userRef.get();

      if (
        currentVoteHistory[entryId] &&
        isSameDay(currentVoteHistory[entryId], new Date())
      ) {
        notify("You already voted for this song today!", "warning");
        return;
      }

      if (userDoc.exists) {
        const userCredits = userDoc.data().credits || 0;

        if (hasVotedToday && userCredits < voteCost) {
          notify("Insufficient credits to vote. Earn more credits!", "warning");
          return;
        }

        // If it's not the first vote and the user has enough credits, update credits
        if (hasVotedToday) {
          // Update user credits
          updateCredits(userId, -voteCost);
          // Update the state variable for user credits
          setUserCredits((prevCredits) => prevCredits - voteCost);
        }

        // Record the user's vote for the day and entry
        await userVoteRef.set({
          userId,
          entryId,
          timestamp: Date.now(),
        });

        // Update the UI by updating the state
        setCurrentVoteHistory((prevEntries) => ({
          ...prevEntries,
          [entryId]: Date.now(),
        }));

        setEntries((prevEntries) =>
          prevEntries.map((entry) =>
            entry.id === entryId ? { ...entry, votes: entry.votes + 1 } : entry
          )
        );

        // Update the entry's votes in the database
        entryRef.update({
          votes: currentVotes + 1,
        });

        const entryUserName = entryDoc.data().user.displayName;

        if (!hasVotedToday) {
          // Update user credits
          updateCredits(userId, 100);
          // Update the state variable for user credits
          setUserCredits((prevCredits) => prevCredits + 100);
          // Display a success message or update UI as needed
          notify(
            <>
              Voted successfully! You have the same music taste as{" "}
              <strong>{entryUserName}</strong>. 100 Credits Received
            </>,
            "success"
          );
        } else {
          // Display a success message or update UI as needed
          notify(
            <>
              Voted successfully! You have the same music taste as{" "}
              <strong>{entryUserName}</strong>. 200 Credits Consumed
            </>,
            "success"
          );
        }
      }
    }
  };

  const openTooltipEvents = {
    mouseenter: true,
    focus: false,
  };

  const closeTooltipEvents = {
    mouseleave: true,
    blur: false,
  };

  const canAddSong = () => {
    if (!votingEnabled) return false;
    if (sortedEntries && weeklySubmitted) {
      if (sortedEntries.length === 30) {
        if (userData.credits > 1600) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className={`main-container card-container ${
          devicePerformance === 10 ? "neon-block" : ""
        }`}
      >
        <div className="card card-custom block">
          {devicePerformance === 10 && <span className="rainbow"></span>}
          <h2 className="title">
            <img
              src={TapThirsty}
              alt="TapThirsty"
              onClick={() => navigate("/")}
            />
          </h2>
          <div className="profile" onClick={() => navigate("/im-thirsty")}>
            <FontAwesomeIcon icon={faBars} size="xl" />
            <img
              src={userData.profile_image_url}
              alt={userData.display_name}
              className="avatar"
            />
            <div className="thirsty-profile-coins">
              <img className="coins" src={Coin} alt="zvinci" />
              {userCredits}
            </div>
          </div>
          <div
            className="rules"
            onClick={() => navigate("/rules")}
            data-tooltip-id="rules-tt"
            data-tooltip-content="You better read this!"
            data-tooltip-place="top"
          >
            <span>RULES.</span>
          </div>
          <Tooltip
            id="rules-tt"
            variant="light"
            clickable={true}
            openEvents={openTooltipEvents}
            closeEvents={closeTooltipEvents}
          />
          <div className="controller">
            <div className="titles">
              <h5 className="subtitle">vote your favourite thirst.</h5>
              <small>
                Best experience on Desktop Devices{" "}
                <FontAwesomeIcon icon={faDesktop} />
              </small>
            </div>

            {canAddSong() ? (
              <div className="add-song-button">
                <Button
                  className="modal-open-button add-song"
                  variant="primary"
                  onClick={handleOpenModalAddSong}
                  data-tooltip-id="addsong-tt"
                  data-tooltip-content={
                    sortedEntries.length >= 30
                      ? "Thirty/Thirsty is the limit, if you have 1600 credits you can replace the least voted song"
                      : "Do you wanna feed the TAP? 3 Songs per Week is the maximum!"
                  }
                  data-tooltip-place="top"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  ADD A SONG
                </Button>
                <Tooltip
                  id="addsong-tt"
                  variant="light"
                  clickable={true}
                  openEvents={openTooltipEvents}
                  closeEvents={closeTooltipEvents}
                />
              </div>
            ) : null}

            {!weeklySubmitted && (
              <div className="add-song-button">
                <span className="add-song">Weekly limit reached!</span>
              </div>
            )}
          </div>
          <div className="list-entries">
            <div className="list-container">
              {entries == null && (
                <SkeletonTheme baseColor="#31343A" highlightColor="#494A50">
                  <div className="loading">
                    <Skeleton
                      height={"80px"}
                      width={"100%"}
                      borderRadius={10}
                      style={{ marginBottom: "10px", opacity: "0.7" }}
                    />
                    <Skeleton
                      height={"80px"}
                      width={"100%"}
                      borderRadius={10}
                      style={{ marginBottom: "10px", opacity: "0.7" }}
                    />
                    <Skeleton
                      height={"80px"}
                      width={"100%"}
                      borderRadius={10}
                      style={{ marginBottom: "10px", opacity: "0.7" }}
                    />
                  </div>
                </SkeletonTheme>
              )}
              {sortedEntries && sortedEntries.length === 0 && (
                <div className="no-entries">
                  It seems that we need a bit of music in our lives. You can
                  help us!
                </div>
              )}
              {sortedEntries &&
                sortedEntries.map((entry, index) => {
                  const currentRank =
                    sortedEntries.findIndex((e) => e.id === entry.id) + 1;
                  const averagePosition = calculateAveragePosition(entry.id);
                  const tendency = getTendency(currentRank, averagePosition);

                  const userVotedToday = currentVoteHistory[entry.id]
                    ? isSameDay(
                        new Date(currentVoteHistory[entry.id]),
                        new Date()
                      )
                    : false;
                  return (
                    <div key={entry.id} id={entry.id}>
                      {index % 10 === 0 && index !== 0 && (
                        <div className="separator">
                          <h4>top {index}.</h4>
                        </div>
                      )}
                      <div
                        className="list-item-container"
                        onClick={() =>
                          window.open("/showcase/" + entry.id, "_blank")
                        }
                      >
                        <span className=" not-on-mobile">
                          <strong>{index + 1}</strong>
                        </span>
                        <div
                          className={`list-item ${
                            window.location.hash === "#" + entry.id
                              ? "selected"
                              : ""
                          }`}
                        >
                          <div className="item-thumbnail list-item-columns-cell">
                            <img
                              src={entry.thumbnail}
                              alt={entry.title}
                              className="thumbnail"
                            />
                          </div>
                          <div className="item-title list-item-columns-cell">
                            {entry.title}
                          </div>
                          <div className="item-controls list-item-columns-cell">
                            <div className="tendency not-on-mobile">
                              {tendency === "higher" && entry.votes !== 0 && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faChevronCircleUp}
                                    size="xl"
                                    className="up"
                                    data-tooltip-id="tendency-up"
                                    data-tooltip-place="top"
                                  />
                                  <Tooltip
                                    id="tendency-up"
                                    variant="light"
                                    content={
                                      <span>
                                        The tendency for this song is{" "}
                                        <strong>ascendant</strong>! The vote
                                        button is over there, on the right!
                                      </span>
                                    }
                                    style={{
                                      zIndex: 9999,
                                      maxWidth: "250px",
                                      lineHeight: "normal",
                                    }}
                                  />
                                </>
                              )}

                              {tendency === "lower" && entry.votes !== 0 && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faChevronCircleDown}
                                    size="xl"
                                    className="down"
                                    data-tooltip-id="tendency-down"
                                    data-tooltip-place="top"
                                  />
                                  <Tooltip
                                    id="tendency-down"
                                    variant="light"
                                    content={
                                      <span>
                                        The tendency for this song is{" "}
                                        <strong>descendant</strong>! The vote
                                        button is over there, on the right!
                                      </span>
                                    }
                                    style={{
                                      zIndex: 9999,
                                      maxWidth: "250px",
                                      lineHeight: "normal",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="item-share list-item-columns-cell">
                            {votingEnabled && (
                              <div
                                className="upvote"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleVote(entry.id);
                                }}
                              >
                                {userVotedToday ? (
                                  <FontAwesomeIcon
                                    icon={faThumbsUp}
                                    size="xl"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faThumbsUpRegular}
                                    size="xl"
                                  />
                                )}
                              </div>
                            )}
                            <FontAwesomeIcon
                              icon={faLink}
                              onClick={(e) => copyShareLink(e, entry.id)}
                              size="xl"
                              className=" not-on-mobile"
                            />
                            {/* {new Date(YTDurationToSeconds(entry.duration) * 1000)
                          .toISOString()
                          .slice(14, 19)} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <button className="btn-logout" onClick={handleLogout}>
          Logout
        </button>
        {/* Add Song Modal */}
        {canAddSong() ? (
          <Modal
            show={showModal}
            onHide={handleCloseModal}
            className="thirsty-modal"
          >
            <Modal.Header data-bs-theme="dark" closeButton>
              <Modal.Title>
                Feed the <Icon preset={"small"} /> with a new song
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="thirsty-modal-profile">
                <div className="thirsty-profile-image">
                  <img
                    src={userData.profile_image_url}
                    alt={userData.display_name}
                  />
                </div>
                <div className="thirsty-profile-name">
                  @{userData.display_name}
                </div>

                <div className="thirsty-profile-name">
                  <img className="coins" src={Coin} alt="zvinci" />
                  {userData.credits}
                </div>
              </div>
              {sortedEntries.length < 30 ? (
                <input
                  type="text"
                  placeholder="Enter YouTube link"
                  value={youtubeLink}
                  onChange={handleYoutubeLinkChange}
                />
              ) : sortedEntries.length === 30 && userData.credits < 1600 ? (
                <small>
                  You do not have enough <Icon preset="small" />
                  credits to replace the least voted song!
                </small>
              ) : (
                <>
                  {sortedEntries.length === 30 && userData.credits >= 1600 && (
                    <input
                      type="text"
                      placeholder="Enter YouTube link"
                      value={youtubeLink}
                      onChange={handleYoutubeLinkChange}
                    />
                  )}
                  {sortedEntries.length === 30 && userData.credits >= 1600 && (
                    <small>
                      This will consume 1600 <Icon preset="small" /> credits
                      from your earnings.
                    </small>
                  )}
                </>
              )}
              {!videoInfo && (
                <small>
                  * For now, we accept only Youtube Videos which are under 5
                  minutes length!
                </small>
              )}
              {videoInfo && (
                <div className="video-preview">
                  <div className="video-thumbnail">
                    <img src={videoInfo.thumbnailUrl} alt="Video Thumbnail" />
                  </div>
                  <div className="video-details">
                    <p>
                      <span className="datas">Title:</span>
                      {videoInfo.title}
                    </p>
                    <p>
                      <span className="datas">Duration:</span>
                      {new Date(YTDurationToSeconds(videoInfo.duration) * 1000)
                        .toISOString()
                        .slice(11, 19)}
                    </p>
                    <p>
                      <span className="datas">Channel:</span>
                      {videoInfo.channelName}
                    </p>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="standard" onClick={handleCloseModal}>
                give up.
              </Button>
              <Button variant="success" onClick={handleAddSong}>
                FEED THE <Icon preset={"small"} />
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </div>
      <ToastContainer theme="dark" />
    </>
  );
}

export default SongsList;
