// src/SongsList.js
import React, { useState, useEffect, useRef } from "react";
import { useTwitch } from "./TwitchContext";
import { db } from "./firebase"; // Assuming you have a `db` export from your firebase file
import "./App.css";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import TapThirsty from "./images/tapThirstyV2.png";
import Icon from "./TapIcon";
import Tippy from "@tippy.js/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPlayer from "react-player";
import { format } from "date-fns";

function Top10Share() {
  const { userData, setTwitchUserData } = useTwitch();
  const navigate = useNavigate();
  const notify = (message, type = null) => {
    if (type) toast[type](message);
    else toast(message);
  };

  const [entries, setEntries] = useState([]);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [videoInfo, setVideoInfo] = useState(null);
  const [entryHistoryPositions, setEntryHistoryPositions] = useState([]);

  const handleLogout = () => {
    // Clear user data and token from storage
    localStorage.removeItem("twitchToken");
    setTwitchUserData(null);
    navigate("/");
  };

  useEffect(() => {
    // Fetch entries and historical rankings from the database
    const fetchData = async () => {
      const entriesSnapshot = await db.collection("entries").get();
      const entriesData = entriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setEntries(entriesData);
    };

    fetchData();
  }, []);

  const cardRef = useRef(null);
  const cardRefV = useRef(null);

  useEffect(() => {
    const handleImageLoad = () => {
      // After images have loaded, you can capture the screenshot
      handleScreenshot();
    };

    // Attach the load event to each image element
    const images = document.querySelectorAll(".thumbnail");
    images.forEach((image) => {
      image.addEventListener("load", handleImageLoad);
    });

    return () => {
      // Remove event listeners when component unmounts
      images.forEach((image) => {
        image.removeEventListener("load", handleImageLoad);
      });
    };
  }, []);

  const handleScreenshot = async () => {
    try {
      if (cardRef.current) {
        const canvas = await html2canvas(cardRef.current, {
          logging: true, // Enable logging for debugging
          useCORS: true,
          allowTaint: true,
          ignoreElements: (element) => {
            // Specify elements to be ignored in the screenshot
            return element.classList.contains("ignore-screenshot");
          },
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "screenshot.png";

        // Trigger the download
        link.click();
      }
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  const handleScreenshotV = async () => {
    try {
      if (cardRefV.current) {
        const canvas = await html2canvas(cardRefV.current, {
          logging: true, // Enable logging for debugging
          useCORS: true,
          allowTaint: true,
          ignoreElements: (element) => {
            // Specify elements to be ignored in the screenshot
            return element.classList.contains("ignore-screenshot");
          },
        });

        // Create a download link
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "screenshot.png";

        // Trigger the download
        link.click();
      }
    } catch (error) {
      console.error("Error capturing screenshot:", error);
    }
  };

  useEffect(() => {
    const fetchHistory = async () => {
      const historySnapshot = await db
        .collection("entryHistoryPositions")
        .get();
      const historyData = historySnapshot.docs.map((doc) => doc.data());
      setEntryHistoryPositions(historyData);
    };

    fetchHistory();
  }, []);

  const calculateAveragePosition = (entryId) => {
    const entryHistory = entryHistoryPositions.filter(
      (history) => history.entryId === entryId
    );
    if (entryHistory.length === 0) {
      return null;
    }

    const totalPositions = entryHistory.reduce(
      (sum, history) => sum + history.position,
      0
    );
    return Math.round(totalPositions / entryHistory.length);
  };

  const getTendency = (currentPosition, averagePosition) => {
    if (!currentPosition || !averagePosition) return "same";
    if (currentPosition < averagePosition) {
      return "higher";
    } else if (currentPosition > averagePosition) {
      return "lower";
    } else {
      return "same";
    }
  };

  const checkAndUpdatePositions = async () => {
    const sortedEntries = [...entries]
      .sort((a, b) => {
        // Sort by votes first
        if (b.votes !== a.votes) {
          return b.votes - a.votes;
        }

        // If votes are the same, sort by timestamp (time they were added)
        return a.timestamp - b.timestamp;
      })
      .slice(0, 10);

    sortedEntries.forEach(async (entry) => {
      const currentEntryHistory =
        entryHistoryPositions.find((history) => history.entryId === entry.id) ||
        [];
      const currentAveragePosition = calculateAveragePosition(entry.id);

      if (currentEntryHistory) {
        const currentPosition = sortedEntries.indexOf(entry) + 1;

        // Check if the position has changed
        if (currentEntryHistory.position !== currentPosition) {
          const tendency = getTendency(currentPosition, currentAveragePosition);

          await db.collection("entryHistoryPositions").add({
            entryId: entry.id,
            timestamp: Date.now(),
            position: currentPosition,
            tendency,
          });

          // Update the entryHistoryPositions state
          setEntryHistoryPositions((prevPositions) => [
            ...prevPositions,
            {
              entryId: entry.id,
              timestamp: Date.now(),
              position: currentPosition,
              tendency,
            },
          ]);
        }
      }
    });
  };

  useEffect(() => {
    checkAndUpdatePositions();

    // Check for the hash and scroll to the element
    if (entries.length && window.location.hash !== "") {
      setTimeout(() => {
        if (window.location.hash === "#newEntry") {
          window.scrollTo(0, document.body.scrollHeight);
          return;
        }
        const newEntryElement = document.getElementById(
          window.location.hash.slice(1)
        ); // Assuming you have an element with the id "newEntry"
        if (newEntryElement) {
          newEntryElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  }, [entries]);

  // Helper function to get the start of the current week
  const getStartOfWeek = () => {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setHours(0, 0, 0, 0);
    startOfWeek.setDate(today.getDate() - today.getDay());
    return startOfWeek;
  };

  const handleAddSong = async () => {
    // Assuming you have the user ID available in userData
    const userId = userData.id;

    // Get the start of the current week
    const startOfWeek = getStartOfWeek();

    // Check the number of entries the user has made this week
    const userEntriesRef = db
      .collection("entries")
      .where("user.id", "==", userId)
      .where("timestamp", ">=", startOfWeek.getTime());

    const userEntriesSnapshot = await userEntriesRef.get();

    // Set your desired limit (e.g., 3 entries per week)
    const weeklyEntryLimit = 3;

    // If the limit is reached, prevent adding a new entry
    if (userEntriesSnapshot.size >= weeklyEntryLimit) {
      notify(
        "Weekly entry limit reached. Cannot add more entries this week.",
        "warning"
      );
      return;
    }
    // Check the current number of entries
    const currentEntryCount = entries.length;

    // Set your desired limit (e.g., 30)
    const entryLimit = 30;

    // Clean the YouTube link to extract the video ID
    const videoIdMatch = youtubeLink.match(
      /(?:youtube\.com\/.*(?:[/]|&v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );

    const videoId = videoIdMatch ? videoIdMatch[1] : null;

    // Check if the video ID already exists in entries
    const duplicateEntry = entries.find((entry) => entry.videoId === videoId);

    if (duplicateEntry) {
      notify("Duplicate entry: This song is already in the list.", "warning");
      return;
    }

    // If the user has enough credits, allow entry submission even if the limit is reached
    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      const userCredits = userDoc.data().credits || 0;

      // Set your credit threshold for submitting entries without limit
      const creditThreshold = 1600;

      if (currentEntryCount >= entryLimit) {
        if (userCredits >= creditThreshold) {
          // If the limit is reached, replace the entry with the least votes
          if (currentEntryCount >= entryLimit) {
            const entryWithLeastVotes = entries.reduce((prev, current) =>
              prev.votes < current.votes ? prev : current
            );

            // Remove the entry with the least votes
            await db.collection("entries").doc(entryWithLeastVotes.id).delete();

            // Update user credits
            updateCredits(userId, -1600);

            // Alert the user about the replacement
            notify(
              `Your entry has replaced the song "${entryWithLeastVotes.title}" with the least votes.`
            );
          }
        } else {
          notify(
            "Insufficient credits to add a new entry. Tap Thirsty comes from THIRTY, got it? THIRSTY, THIRTY... Thirty songs is the limit if you do not have 1600 credits.",
            "warning"
          );
          return;
        }
      }
    } else {
      return;
    }
    // Add the new entry to the database
    db.collection("entries")
      .add({
        title: videoInfo.title,
        duration: videoInfo.duration,
        channelName: videoInfo.channelName,
        thumbnail: videoInfo.thumbnailUrl,
        youtubeLink,
        videoId, // Store the video ID
        user: {
          id: userData.id,
          displayName: userData.display_name,
          avatar: userData.profile_image_url,
        },
        votes: 0,
        timestamp: Date.now(),
      })
      .then(async () => {
        await updateCredits(userData.id, 300);
        window.location.hash = "newEntry";

        // Reload the page after adding the song
        window.location.reload();
      })
      .catch((error) => {
        notify("Error adding song, try again later");
      });
  };

  const updateCredits = async (userId, creditsToAdd) => {
    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    if (userDoc.exists) {
      const currentCredits = userDoc.data().credits || 0;
      const newCredits = currentCredits + creditsToAdd;

      // Update user credits in the database
      await userRef.update({ credits: newCredits });
    }
  };

  const handleYoutubeLinkChange = (event) => {
    const link = event.target.value;
    setYoutubeLink(link);

    // Fetch video info when a valid YouTube link is provided
    // This is a simplified check, you might want to improve it
    if (link.includes("youtube.com")) {
      // You can use an external service or your own API to get video information based on the YouTube link
      // Here, we assume the link is in the format "https://www.youtube.com/watch?v=VIDEO_ID"
      const videoId = link.split("v=")[1];
      if (!videoId) {
        notify("Incorrect Youtube video link!", "warning");
      }
      const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet,contentDetails&key=AIzaSyDA4Zn-63m5pu7ppmZzSJymT1PYQ_39WOs`;

      // Fetch video information
      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          const videoSnippet = data.items[0].snippet;
          const duration = data.items[0].contentDetails.duration;

          setVideoInfo({
            title: videoSnippet.title,
            channelName: videoSnippet.channelTitle,
            duration,
            thumbnailUrl: videoSnippet.thumbnails.high.url,
          });
        })
        .catch((error) => {
          console.error("Error fetching YouTube video info:", error);
        });
    } else {
      // Reset video info if the link is not valid
      setVideoInfo(null);
    }
  };
  // Sort entries based on votes in descending order
  const sortedEntries = [...entries]
    .sort((a, b) => {
      // Sort by votes first
      if (b.votes !== a.votes) {
        return b.votes - a.votes;
      }

      // If votes are the same, sort by timestamp (time they were added)
      return a.timestamp - b.timestamp;
    })
    .slice(0, 10);

  const copyShareLink = (e, entryId) => {
    e.stopPropagation();
    const link = window.location.hostname + "/showcase/" + entryId;
    navigator.clipboard.writeText(link).then(
      function () {
        notify("The link was coppied to clipboard!");
      },
      function (err) {
        notify("Couldn't copy the link to clipboard. Try again!");
      }
    );
  };

  const handleVote = async (entryId) => {
    const userId = userData.id;
    const entryRef = db.collection("entries").doc(entryId);
    const entryDoc = await entryRef.get();

    const userRef = db.collection("users").doc(userId);
    const userDoc = await userRef.get();

    // Check if the user has already voted today
    const today = new Date().toISOString().split("T")[0];
    const userVoteRef = db.collection("votes").doc(`${userId}_${today}`);
    const userVoteDoc = await userVoteRef.get();

    if (userVoteDoc.exists) {
      notify("You already voted a song today, see ya tomorrow!!!", "warning");
      // You may want to display a message to the user or handle this case differently
      return;
    }

    if (entryDoc.exists && userDoc.exists) {
      const currentVotes = entryDoc.data().votes || 0;

      const voteHistory = userDoc.data().voteHistory || {};

      // Update the user's vote history
      voteHistory[entryId] = Date.now();
      await userRef.update({ voteHistory });

      // Update user credits
      updateCredits(userId, 100);

      entryRef.update({
        votes: currentVotes + 1,
      });
      // Display a success message or update UI as needed
      notify("Vote recorded successfully! 100 Credits Received");

      // Update votes in the state
      setEntries((prevEntries) =>
        prevEntries.map((entry) =>
          entry.id === entryId
            ? {
                ...entry,
                votes: currentVotes + 1,
              }
            : entry
        )
      );

      // Record the user's vote for the day
      await userVoteRef.set({
        userId,
        entryId,
        timestamp: Date.now(),
      });
    }
  };

  return (
    <div className="screenshot-container" ref={cardRef}>
      <div className="card-container neon-block top-10">
        <div className="card card-custom block" ref={cardRefV}>
          <span className="rainbow"></span>
          <h2 className="title">
            <img src={TapThirsty} alt="TapThirsty" />
          </h2>
          <h6>
            <strong>{format(new Date(), "cccc LLLL d, yyyy")}</strong>
          </h6>
          <button className="ignore-screenshot" onClick={handleScreenshot}>
            Capture Screenshot
          </button>
          <button className="ignore-screenshot" onClick={handleScreenshotV}>
            Capture Screenshot VERTICAL
          </button>
          <h5 className="subtitle">top 10 favourite thirsts.</h5>
          <div className="list-entries">
            <div className="list-container">
              {sortedEntries.map((entry, index) => {
                return (
                  <div key={entry.id} id={entry.id}>
                    {index % 10 === 0 && index !== 0 && (
                      <div className="separator">
                        <h4>top {index}.</h4>
                      </div>
                    )}
                    <div
                      className="list-item-container"
                      onClick={() =>
                        window.open("/showcase/" + entry.id, "_blank")
                      }
                    >
                      <div
                        className={`list-item ${
                          window.location.hash === "#" + entry.id
                            ? "selected"
                            : ""
                        }`}
                      >
                        <div>
                          <span className="entry-rank not-on-mobile">
                            <strong>{index + 1}</strong>
                          </span>
                        </div>
                        <div className="item-title list-item-columns-cell">
                          {entry.title}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Top10Share;
